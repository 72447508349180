import { trigger, transition, style, query, animateChild, group, animate } from '@angular/animations';

export const slideFromRight = [
    style({ position: 'relative' }),
    query(':enter, :leave', [
        style({
            position: 'absolute',
            top: 0,
            right: 0,
            width: '100%'
        })
    ]),
    query(':enter', [
        style({ right: '-100%' })
    ]),
    query(':leave', animateChild()),
    group([
        query(':leave', [
            animate('800ms ease-out', style({ right: '100%' }))
        ]),
        query(':enter', [
            animate('800ms ease-out', style({ right: '0%' }))
        ])
    ]),
    query(':enter', animateChild()),
];

export const slideFromLeft = [
    style({ position: 'relative' }),
    query(':enter, :leave', [
        style({
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%'
        })
    ]),
    query(':enter', [
        style({ left: '-100%' })
    ]),
    query(':leave', animateChild()),
    group([
        query(':leave', [
            animate('800ms ease-out', style({ left: '100%' }))
        ]),
        query(':enter', [
            animate('800ms ease-out', style({ left: '0%' }))
        ])
    ]),
    query(':enter', animateChild()),
];

// TODO- fix
export const slideFromTop = [
    style({ position: 'relative' }),
    query(':enter, :leave', [
        style({
            position: 'absolute',
            top: 0,
            right: 0,
            width: '100%'
        })
    ]),
    query(':enter', [
        style({ top: '-100%' })
    ]),
    query(':leave', animateChild()),
    group([
        query(':leave', [
            animate('2800ms ease-out', style({ top: '100%' }))
        ]),
        query(':enter', [
            animate('2800ms ease-out', style({ top: '0%' }))
        ])
    ]),
    query(':enter', animateChild()),
];

// TODO- fix
export const slideFromBottom = [
    style({ position: 'relative' }),
    query(':enter, :leave', [
        style({
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%'
        })
    ]),
    query(':enter', [
        style({ bottom: '-100%' })
    ]),
    query(':leave', animateChild()),
    group([
        query(':leave', [
            animate('2800ms ease-out', style({ bottom: '100%' }))
        ]),
        query(':enter', [
            animate('2800ms ease-out', style({ bottom: '0%' }))
        ])
    ]),
    query(':enter', animateChild()),
];

