import { trigger, transition, style, query, animateChild, group, animate } from '@angular/animations';
import { slideFromRight, slideFromLeft, slideFromTop, slideFromBottom } from './animations-states';

export const routeAnimations =
    trigger('routeAnimations', [
        //LANDING PAGE to ABOUT
        transition('LandingPage => Experience', slideFromRight),
        transition('Experience => LandingPage', slideFromLeft),
        transition('Education => LandingPage', slideFromLeft),
        transition('Skills => LandingPage', slideFromLeft),

        //ABOUT to ABOUT -- TODO: make these top/bottom instead of right/left
        transition('Experience => Education', slideFromRight),
        transition('Education => Experience', slideFromLeft),

        transition('Education => Skills', slideFromRight),
        transition('Skills => Education', slideFromLeft),

        transition('Experience => Skills', slideFromRight),
        transition('Skills => Experience', slideFromLeft),

        //LANDING PAGE to PROJECTS
        transition('LandingPage => Projects', slideFromLeft),
        transition('Projects => LandingPage', slideFromRight),

        //LANDING PAGE to PROFILE-- TODO: make these top/bottom instead of right/left
        transition('LandingPage => Profile', slideFromRight),
        transition('Profile => LandingPage', slideFromLeft),
    ]);